import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';  // Import the NavBar CSS
import logo from '../assets/logo.png';  // Import the logo from assets

const NavBar = () => {
  return (
    <nav className="navbar">
      {/* Navbar logo */}
      <div className="navbar-logo">
        <img src={logo} alt="QVLSwap Logo" /> {/* Use the imported logo */}
      </div>

      {/* Navbar links */}
      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/qvlswap">QVLSwap</Link></li>
        <li><Link to="/vault">Vault</Link></li>
      </ul>

      {/* Connect Wallet Button */}
      <button className="connect-wallet-btn">Connect Wallet</button>
    </nav>
  );
};

export default NavBar;
