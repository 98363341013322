import React, { useState, Suspense } from 'react';
import './SwapWidget.css';  // Import the SwapWidget CSS
import logo from '../assets/logo.png';  // Import the logo from assets

const SwapWidget = React.lazy(() => import('@uniswap/widgets').then(mod => ({ default: mod.SwapWidget })));

const InHouseAlgo = () => {
  return (
    <div>
      <h2>In-house Algorithm Vault</h2>
      <p>Add liquidity to our algorithm-based strategies and optimize your returns.</p>
      <div className="algo-content">
        <button className="invest-btn">Add liquidity</button>
      </div>
    </div>
  );
};

const SwapWidgetComponent = () => {
  const [activeTab, setActiveTab] = useState('HeroSwap');

  const jsonRpcUrlMap = {
    1: ['https://mainnet.infura.io/v3/b8469a9e72054dd682507826aa21c88c'],
  };

  const tokenList = 'https://ipfs.io/ipns/tokens.uniswap.org';

  const renderWidget = () => {
    if (activeTab === 'Uniswap') {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <SwapWidget jsonRpcUrlMap={jsonRpcUrlMap} tokenList={tokenList} />
        </Suspense>
      );
    } else if (activeTab === 'HeroSwap') {
      return (
        <iframe
          src="https://heroswap.com/widget?affiliateName=quantumvoidlabs&destinationAddress=&theme=dark-icy&destinationTicker=SUI"
          title="HeroSwap Widget"
          width="400"
          height="500"
          frameBorder="0"
          style={{ border: '0', borderRadius: '12px' }}
        />
      );
    } else if (activeTab === 'In-house') {
      return <InHouseAlgo />;
    }
  };

  return (
    <div className="App">
      {/* Use the imported logo */}
      <img src={logo} alt="QVLSwap logo" className="logo" />
      <div className="tab-navigation">
        <button onClick={() => setActiveTab('Uniswap')} className={activeTab === 'Uniswap' ? 'active-tab' : ''}>
          Uniswap
        </button>
        <button onClick={() => setActiveTab('HeroSwap')} className={activeTab === 'HeroSwap' ? 'active-tab' : ''}>
          HeroSwap
        </button>
        <button onClick={() => setActiveTab('In-house')} className={activeTab === 'In-house' ? 'active-tab' : ''}>
          In-house Algorithms
        </button>
      </div>
      <div className="widget-container">{renderWidget()}</div>
    </div>
  );
};

export default SwapWidgetComponent;
