import React from 'react';
import './Vault.css';  // Make sure you have Vault-specific styles

const Vault = () => {
  return (
    <div className="vault-container">
      <div className="vault-content">
        <div className="vault-lock">
          🔒 {/* Big Lock Emoji */}
        </div>
        <h2>In-house Algorithm Vault</h2>
        <p>Add $PUMPKIN or $SUI to our in-house algorithm-based strategies and optimize your returns.</p>
        <button className="invest-btn">Add liquidity</button>
      </div>
    </div>
  );
};

export default Vault;
