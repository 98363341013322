import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'; // Import the Home page
import SwapWidget from './components/SwapWidget'; // Import the SwapWidget component
import Vault from './components/Vault'; // Import the Vault component
import NavBar from './components/NavBar'; // Import the NavBar for navigation

const App = () => {
  return (
    <Router>
      <div className="App">
        {/* Add the NavBar at the top */}
        <NavBar />
        <Routes>
          {/* Route for Home page which defaults to QVLSwap */}
          <Route path="/" element={<Home />} />
          {/* Separate route for the QVLSwap page */}
          <Route path="/qvlswap" element={<SwapWidget />} />
          {/* Separate route for Vault / In-house Algorithm */}
          <Route path="/vault" element={<Vault />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
